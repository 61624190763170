"use client";

import moment from "moment";
import Link from "next/link";

import { Typography } from "@/components/shared/Typography";
import { useTheme } from "@/components/providers/ThemeProvider";
import { getImageUrl } from "@/utils/getImageUrl";

interface HeaderLogoProps {
  showDate?: boolean;
  logoUrl?: string;
  darkLogoUrl?: string;
}

export default function HeaderLogo({ showDate = true, logoUrl, darkLogoUrl }: HeaderLogoProps) {
  const { darkMode } = useTheme();

  return (
    <div className="flex items-end gap-3">
      <Link href={"/"} aria-label="Go to home page">
        <img
          className="w-[240px] h-[45px] lg:w-[280px] lg:h-[50px] object-cover"
          alt="Deshabhimani"
          src={
            logoUrl
              ? darkMode && darkLogoUrl
                ? getImageUrl(darkLogoUrl)
                : getImageUrl(logoUrl)
              : !darkMode
              ? "/deshabhimanilogo.svg"
              : "/deshabhimanilogo-white.svg"
          }
        />
      </Link>
      {showDate && (
        <Typography variant="body3" className="hidden lg:block text-[#ADADAD] font-medium">
          {moment(new Date()).format("dddd DD, MMMM YYYY")}
        </Typography>
      )}
    </div>
  );
}
