"use client";

import Link from "next/link";

import { ArticleTagType } from "@/types/article";
import { SocialLinkType } from "@/types/common";
import { SocialIconsKey, socialIconsMap } from "@/data/social";
import { BsGlobe2 } from "react-icons/bs";
import { ensureHttps } from "@/utils/ensureHttps";
import { usePathname } from "next/navigation";

type TrendingTopicsProps = {
  trendingTopics: ArticleTagType[];
  socialLinks: SocialLinkType[];
};

export default function HeaderTrendingTopics({ trendingTopics, socialLinks }: TrendingTopicsProps) {
  const pathname = usePathname();

  return (
    <div className="overflow-hidden bg-secondaryGray dark:bg-darkBgSecondaryColor h-14 ">
      <div className="container flex justify-between h-full gap-2">
        <div className="flex items-center gap-3 overflow-x-scroll no-scrollbar">
          <p className="hidden text-sm font-medium text-black uppercase lg:block whitespace-nowrap dark:text-white ">
            Trending Topics
          </p>
          {trendingTopics?.map((topic, index) => (
            <Link
              key={index}
              href={`/topics/tag/${topic?.slug}`}
              aria-label={`View all news in the tag: ${topic?.name}`}
            >
              <span className="block bg-white dark:bg-darkBgPrimaryColor dark:text-white px-4 whitespace-nowrap rounded-full text-[11px] font-medium py-1 font-anek tracking-wide">
                {topic?.name}
              </span>
            </Link>
          ))}
        </div>
        <div className="gap-3 hidden lg:flex">
          <a
            href={`/epaper`}
            target="_blank"
            className="flex items-center font-anek"
            aria-label="Open the E-paper in a new tab"
          >
            <p className="text-xs font-bold text-yellow-500 uppercase whitespace-nowrap">E-paper</p>
          </a>
          {pathname?.startsWith("/english") ? (
            <Link
              href={`/`}
              className="flex items-center font-anek"
              aria-label="Open the മലയാളം in a new tab"
            >
              <p className="text-sm font-anek font-bold dark:text-white uppercase whitespace-nowrap">
                മലയാളം
              </p>
            </Link>
          ) : (
            <Link
              href={`/english`}
              className="flex items-center font-anek"
              aria-label="Open the English in a new tab"
            >
              <p className="text-xs font-bold dark:text-white uppercase whitespace-nowrap">
                English
              </p>
            </Link>
          )}

          {/* <div className="flex items-center font-anek">
            <p className="text-xs font-bold text-black uppercase dark:text-white">English</p>
          </div> */}
          <div className="flex gap-3">
            {socialLinks?.map((socialLink, index) => {
              const Icon = socialIconsMap[socialLink?.platform as SocialIconsKey] || BsGlobe2;
              return (
                <div key={index} className="flex items-center font-anek">
                  <p className="w-6 h-6 rounded-md shrink-0 bg-[#585858] text-white dark:bg-white dark:text-black  flex justify-center items-center">
                    <a
                      href={ensureHttps(socialLink?.url)}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={`Follow us on ${socialLink?.platform}`}
                    >
                      <Icon />
                    </a>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
