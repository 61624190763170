"use client";

import { AuthContext } from "@/components/providers/AuthContextProvider";
import { useHandleClickOutside } from "@/hooks";
import Link from "next/link";
import { useContext, useRef } from "react";

interface UserDropdownProps {
  setUserDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function UserDropdown({ setUserDropdownOpen }: UserDropdownProps) {
  const drodownRef = useRef(null);
  useHandleClickOutside(drodownRef, () => setUserDropdownOpen(false));
  const context = useContext(AuthContext);

  return (
    <div
      ref={drodownRef}
      className="absolute top-[32px] right-0 bg-white shadow-md rounded min-w-[180px] border-t-4 border-t-[#FF0004] z-10"
    >
      <ul>
        <li>
          <Link
            href="/user/profile"
            className="block px-4 py-1 transition-all hover:bg-[#f7f7f7] text-[15px] font-medium"
            onClick={() => setUserDropdownOpen(false)}
            aria-label="Got to profile"
          >
            Profile
          </Link>
        </li>
        <li
          onClick={() => {
            // window.location.reload();
            context?.logout();
          }}
          className="block px-4 py-1 transition-all hover:bg-[#f7f7f7] cursor-pointer text-[15px] font-medium"
        >
          Logout
        </li>
      </ul>
    </div>
  );
}
