"use client";

import { BsSearch } from "react-icons/bs";
import { MdMenu } from "react-icons/md";
import Link from "next/link";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { FaHome, FaMoon, FaSun } from "react-icons/fa";

import { ArticleCategoryType } from "@/types/article";
import SearchModal from "./SearchModal";
import HamburgerMenuModal from "./HamburgerMenuModal";
import { AuthContext } from "@/components/providers/AuthContextProvider";
import UserDropdown from "./UserDropdown";
import { useTheme } from "@/components/providers/ThemeProvider";
import { SocialLinkType } from "@/types/common";

interface HeaderMainNavigationProps {
  mainNavLinks: ArticleCategoryType[];
  categories: ArticleCategoryType[];
  searchModalOpen: boolean;
  setSearchModalOpen: Dispatch<SetStateAction<boolean>>;
  hamburgerMenuOpen: boolean;
  setHamburgerMenuOpen: Dispatch<SetStateAction<boolean>>;
  socialLinks: SocialLinkType[];
}

export default function HeaderMainNavigation({
  mainNavLinks,
  categories,
  searchModalOpen,
  setSearchModalOpen,
  hamburgerMenuOpen,
  setHamburgerMenuOpen,
  socialLinks,
}: HeaderMainNavigationProps) {
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);

  const { darkMode, toggleDarkMode } = useTheme();
  const authContext = useContext(AuthContext);

  if (!authContext) {
    return null;
  }

  const { user } = authContext;

  return (
    // overflow-hidden removed because of drodown menu
    <div className="bg-[#8B8B8B] h-10  transition-all duration-500">
      <div className="container flex items-center justify-between h-full gap-2">
        {/* Category section */}
        <div className="lg:w-[85%] w-full flex gap-6 items-center h-full overflow-x-scroll no-scrollbar ">
          <button
            className="hidden text-lg text-white lg:block"
            onClick={() => setHamburgerMenuOpen(true)}
            aria-label="Menu"
          >
            <MdMenu />
          </button>
          {hamburgerMenuOpen && (
            <HamburgerMenuModal
              setHamburgerMenuOpen={setHamburgerMenuOpen}
              categories={categories}
              socialLinks={socialLinks}
            />
          )}
          <Link
            href={`/`}
            className="hidden text-lg text-white lg:block"
            aria-label="Go to Home Page"
          >
            <FaHome />
          </Link>

          <div className="flex items-center gap-8 text-white">
            <Link
              href={`/latest-news`}
              className="text-xs uppercase whitespace-nowrap"
              aria-label={`Go to latest news page`}
            >
              Latest News
            </Link>
            {mainNavLinks?.map((navLink, index) => (
              <Link
                href={`/${navLink?.slug}`}
                key={index}
                className="text-xs uppercase whitespace-nowrap"
                aria-label={`Go to ${navLink?.name} page`}
              >
                {navLink?.name}
              </Link>
            ))}
          </div>
        </div>
        {/* Search Section */}
        <div className="items-center hidden h-full gap-6 lg:flex">
          <button className="text-lg text-white" onClick={toggleDarkMode} aria-label="Toggle Theme">
            {darkMode ? <FaSun /> : <FaMoon />}
          </button>

          <button
            className="text-lg text-white"
            onClick={() => setSearchModalOpen(true)}
            aria-label="Search"
          >
            <BsSearch strokeWidth={"0.3"} />
          </button>
          {!user ? (
            <Link href={`/register`} aria-label="Go to register page">
              <button
                className="bg-[#E80D40] text-white rounded-full px-3 text-[12px] h-[28px] font-[600]"
                aria-label="Register"
              >
                Register
              </button>
            </Link>
          ) : (
            <div className="relative">
              <div
                className="size-[28px] rounded-full overflow-hidden cursor-pointer"
                onClick={() => setUserDropdownOpen(true)}
              >
                <img
                  src={user?.pictureURL || "/images/avatar.webp"}
                  alt="avatar"
                  className="object-cover w-full h-full"
                />
              </div>

              {userDropdownOpen && <UserDropdown setUserDropdownOpen={setUserDropdownOpen} />}
            </div>
          )}
        </div>
        {searchModalOpen && (
          <SearchModal setSearchModalOpen={setSearchModalOpen} socialLinks={socialLinks} />
        )}
      </div>
    </div>
  );
}
