"use client";

import { useState } from "react";
import { BsSearch } from "react-icons/bs";
import { FiAlignCenter } from "react-icons/fi";

import HeaderLogo from "./HeaderLogo";
import HeaderMainNavigation from "./HeaderMainNavigation";
import { ArticleCategoryType } from "@/types/article";
import { SocialLinkType } from "@/types/common";
import Link from "next/link";

interface MainHeaderSectionProps {
  mainNavLinks: ArticleCategoryType[];
  categories: ArticleCategoryType[];
  logoUrl?: string;
  darkLogoUrl?: string;
  socialLinks: SocialLinkType[];
}

export default function MainHeaderSection({
  mainNavLinks,
  categories,
  logoUrl,
  darkLogoUrl,
  socialLinks,
}: MainHeaderSectionProps) {
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);

  return (
    <>
      <div className="sticky top-0 z-20 transition-all duration-500 bg-white dark:bg-darkBgPrimaryColor border-b border-[#E6E6E6] dark:border-darkBgSecondaryColor ">
        <div className="container  ">
          <div className="flex items-center self-stretch justify-between py-3">
            {/* Logo Section */}
            <div onClick={() => setHamburgerMenuOpen(true)} className="lg:hidden dark:text-white">
              <FiAlignCenter size={30} />
            </div>
            <HeaderLogo logoUrl={logoUrl} darkLogoUrl={darkLogoUrl} />
            <div className="hidden lg:block w-[550px] max-w-full h-[70px] bg-[#F3F3F3] rounded">
              {/* <Advertisement /> */}
              <img src="/images/temp/header-ad.jpg" alt="ad" />
            </div>
            <div onClick={() => setSearchModalOpen(true)} className="lg:hidden dark:text-white">
              <BsSearch size={27} />
            </div>
          </div>
          <div className="flex lg:hidden justify-center py-1">
            {/* <p className="">English</p> */}
            <div className="flex items-center  divide-x ">
              <Link href={`/`} aria-label="Open the Malayalam Newses in a new tab">
                <p className="px-1 text-sm font-semibold dark:text-white  uppercase whitespace-nowrap font-anek">
                  മലയാളം
                </p>
              </Link>
              <Link href={`/english`} aria-label="Open the English Newses in a new tab">
                <p className="px-1 text-xs font-semibold dark:text-white uppercase whitespace-nowrap">
                  English
                </p>
              </Link>
              <a href={`/epaper`} target="_blank" aria-label="Open the E-paper in a new tab">
                <p className="px-1 text-xs font-semibold text-yellow-500 uppercase whitespace-nowrap">
                  E-paper
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Navigations */}
      <HeaderMainNavigation
        searchModalOpen={searchModalOpen}
        setSearchModalOpen={setSearchModalOpen}
        hamburgerMenuOpen={hamburgerMenuOpen}
        setHamburgerMenuOpen={setHamburgerMenuOpen}
        mainNavLinks={mainNavLinks || []}
        categories={categories || []}
        socialLinks={socialLinks || []}
      />
    </>
  );
}
