"use client";

import Link from "next/link";
import Slider, { Settings } from "react-slick";
import { IoClose } from "react-icons/io5";
import { useQuery } from "@tanstack/react-query";
import { BsGlobe2 } from "react-icons/bs";

import { Typography } from "../../shared/Typography";
import CustomImage from "../../shared/CustomImage";
import SearchInput from "@/components/shared/SearchInput";
import { fetchSearchData } from "@/services/commonService";
import { getNewsDetailsUrl } from "@/utils/getNewsDetailsUrl";
import { ArticleListType } from "@/types/article";
import { getImageUrl } from "@/utils/getImageUrl";
import { useTheme } from "@/components/providers/ThemeProvider";
import { SocialLinkType } from "@/types/common";
import { SocialIconsKey, socialIconsMap } from "@/data/social";
import { ensureHttps } from "@/utils/ensureHttps";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useEffect, useState } from "react";

type SearchDataType = {
  keywords: string[];
  news: {
    docs: ArticleListType[];
  };
};

interface SearchModalProps {
  setSearchModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  socialLinks: SocialLinkType[];
}

export default function SearchModal({ setSearchModalOpen, socialLinks }: SearchModalProps) {
  const [modalHeight, setModalHeight] = useState("100vh");

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { darkMode } = useTheme();
  const { data, error, isLoading } = useQuery<SearchDataType>({
    queryKey: ["fetchSearchData"],
    queryFn: fetchSearchData,
  });

  useEffect(() => {
    const updateHeight = () => {
      const height = `${window.innerHeight}px`;
      setModalHeight(height);
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  return (
    <div
      className="fixed top-0 left-0 z-50 w-full min-h-full bg-white dark:bg-darkBgPrimaryColor"
      style={{ height: modalHeight }}
    >
      {/* CLOSE BUTTON */}
      <div className="container relative">
        <button
          className="absolute top-5 lg:top-10 right-5 text-2xl text-[#333] dark:text-white"
          onClick={() => setSearchModalOpen(false)}
          aria-label="Close"
        >
          <IoClose />
        </button>

        <div className="pt-8 lg:pt-16">
          <Link href={`/`} onClick={() => setSearchModalOpen(false)} aria-label="Go to home page">
            <img
              className="w-[240px] h-[45px] lg:w-[280px] lg:h-[50px] object-cover mx-auto"
              alt="Deshabhimani"
              src={!darkMode ? "/deshabhimanilogo.svg" : "/deshabhimanilogo-white.svg"}
            />
          </Link>
        </div>

        <div className="flex items-center justify-center gap-3 mt-6">
          {socialLinks?.map((socialLink, index) => {
            const Icon = socialIconsMap[socialLink?.platform as SocialIconsKey] || BsGlobe2;
            return (
              <div key={index} className="flex items-center font-anek">
                <p className="w-6 h-6 rounded-md shrink-0 bg-[#585858] text-white dark:bg-white dark:text-black  flex justify-center items-center">
                  <a
                    href={ensureHttps(socialLink?.url)}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`Follow us on ${socialLink?.platform}`}
                  >
                    <Icon />
                  </a>
                </p>
              </div>
            );
          })}
        </div>

        <div className="mt-8 lg:mt-10">
          <SearchInput handleSearch={() => setSearchModalOpen(false)} />
        </div>

        <Typography className="mt-8 text-center lg:mt-10" variant="h4">
          Most Searched Keywords & News
        </Typography>

        {!isLoading && (
          <div className="flex flex-wrap items-center justify-center gap-4 mt-6 lg:gap-10">
            {data?.keywords?.map((keyword, index) => {
              return (
                <Link
                  href={`/search?q=${keyword}`}
                  key={index}
                  onClick={() => setSearchModalOpen(false)}
                  aria-label="Search keyword"
                >
                  <span className="text-[#606060] font-[600] capitalize">{keyword}</span>
                </Link>
              );
            })}
          </div>
        )}
      </div>

      {/* NEWS SLIDER */}
      {!isLoading && (
        <div className="mt-12 mb-8 overflow-hidden">
          <Slider {...settings}>
            {data?.news?.docs?.map((article, index) => {
              const { image, slug, title, categoryId, subCategoryId } = article;
              return (
                <div key={index} className="pl-3 pr-3">
                  <div className="bg-[#f1f1f1] dark:bg-black p-4 rounded-md group">
                    <div className="w-full overflow-hidden rounded-md aspect-video">
                      <Link
                        href={getNewsDetailsUrl(categoryId, subCategoryId, slug)}
                        aria-label={`View news: ${title}`}
                        onClick={() => setSearchModalOpen(false)}
                      >
                        <CustomImage
                          src={getImageUrl(image?.sizes?.sd?.url || image?.url)}
                          alt={image?.alt || ""}
                          className="object-cover w-full h-full img-zoom-hover"
                        />
                      </Link>
                    </div>
                    <Typography variant="h5" language="ml" className="mt-3 line-clamp-2">
                      <Link
                        href={getNewsDetailsUrl(categoryId, subCategoryId, slug)}
                        aria-label={`View news: ${title}`}
                        onClick={() => setSearchModalOpen(false)}
                      >
                        {title}
                      </Link>
                    </Typography>

                    <div className="flex gap-4 mt-3 uppercase divide-x-2 divide-black dark:divide-white">
                      <Link
                        href={`/${categoryId?.slug}`}
                        aria-label={`View all news in the category: ${categoryId?.name}`}
                        onClick={() => setSearchModalOpen(false)}
                      >
                        <Typography className="font-medium" variant="body3">
                          {categoryId?.name}
                        </Typography>
                      </Link>
                      <Link
                        href={`/${categoryId?.slug}/${subCategoryId?.slug}`}
                        aria-label={`View all news in the sub-category: ${subCategoryId?.name}`}
                        onClick={() => setSearchModalOpen(false)}
                      >
                        <Typography
                          className="pl-4 text-secondaryText dark:text-darkBgSecondaryColor"
                          variant="body3"
                        >
                          {subCategoryId?.name}
                        </Typography>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      )}
    </div>
  );
}
