"use client";

import { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <>
      <button
        className={
          "hidden fixed bottom-10 right-8 bg-[#e01d24] size-10 md:flex items-center justify-center rounded-full text-white z-20 transition-all duration-500 ease-in-out " +
          (isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-[100px]")
        }
        onClick={scrollToTop}
        aria-label="Scroll To Top"
      >
        <FaArrowUp />
      </button>
    </>
  );
}
