import { useEffect } from "react";

export const usePreventCopy = (maxCopyLength: number, creditMessage: string) => {
  useEffect(() => {
    const handleCopy = (event: ClipboardEvent) => {
      if (!(event.target instanceof Element)) return;
      const targetElement = event.target.closest(".comment__container");
      if (targetElement) return;

      const selection = window.getSelection();
      if (!selection) return;

      const selectedText = selection.toString();
      let limitedText = selectedText;
      if (selectedText.length > maxCopyLength) {
        limitedText = selectedText.slice(0, maxCopyLength) + "...";
      }

      const finalText = `${limitedText}\n\n${creditMessage}`;

      event.clipboardData?.setData("text/plain", finalText);
      event.preventDefault();
    };

    document.addEventListener("copy", handleCopy);

    return () => {
      document.removeEventListener("copy", handleCopy);
    };
  }, [maxCopyLength, creditMessage]);
};
