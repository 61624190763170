"use client";

import Link from "next/link";
import { use, useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { FaMoon, FaSun } from "react-icons/fa";
import { BsGlobe2 } from "react-icons/bs";

import SearchInput from "@/components/shared/SearchInput";
import { ArticleCategoryType } from "@/types/article";
import { useTheme } from "@/components/providers/ThemeProvider";
import { SocialLinkType } from "@/types/common";
import { SocialIconsKey, socialIconsMap } from "@/data/social";
import { ensureHttps } from "@/utils/ensureHttps";

interface HamburgerMenuModalProps {
  setHamburgerMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  categories: ArticleCategoryType[];
  socialLinks: SocialLinkType[];
}

const tabs = [
  {
    key: "categories",
    name: "Categories",
  },
  // { key: "about-us", name: "About Us" },
  // { key: "our-specials", name: "Our Specials" },
];

const customPages = [
  { name: "E-paper", link: "/epaper" },
  { name: "Latest News", link: "/latest-news" },
  { name: "Articles & Periodicals", link: "/articles-periodicals" },
  { name: "Interviews", link: "/interviews" },
  { name: "Shorts", link: "/videos/shorts" },
  { name: "Visual Stories", link: "/visual-stories" },
  { name: "Photo Stories", link: "/photo-stories" },
  { name: "Podcasts", link: "/podcasts" },
];

export default function HamburgerMenuModal({
  setHamburgerMenuOpen,
  categories,
  socialLinks,
}: HamburgerMenuModalProps) {
  const [modalHeight, setModalHeight] = useState("100vh");

  const { darkMode, toggleDarkMode } = useTheme();

  useEffect(() => {
    const updateHeight = () => {
      const height = `${window.innerHeight}px`;
      setModalHeight(height);
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  useEffect(() => {
    document.body.classList.add("overflow-hidden");

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  return (
    <div
      className="fixed top-0 left-0 z-50 w-full min-h-full overflow-auto bg-white dark:bg-darkBgPrimaryColor"
      style={{ height: modalHeight }}
    >
      <div className="container relative">
        <button
          className="absolute top-5 lg:top-10 right-5 text-2xl text-[#333] dark:text-white "
          onClick={() => setHamburgerMenuOpen(false)}
          aria-label="Close"
        >
          <IoClose />
        </button>

        <div className="pt-8 lg:pt-16">
          <Link href={`/`} onClick={() => setHamburgerMenuOpen(false)} aria-label="Go to home page">
            <img
              className="w-[240px] h-[45px] lg:w-[280px] lg:h-[50px] object-cover mx-auto"
              alt="Deshabhimani"
              src={!darkMode ? "/deshabhimanilogo.svg" : "/deshabhimanilogo-white.svg"}
            />
          </Link>
        </div>

        <div className="flex items-center justify-center gap-3 mt-6">
          {socialLinks?.map((socialLink, index) => {
            const Icon = socialIconsMap[socialLink?.platform as SocialIconsKey] || BsGlobe2;
            return (
              <div key={index} className="flex items-center font-anek">
                <p className="w-6 h-6 rounded-md shrink-0 bg-[#585858] text-white dark:bg-white dark:text-black  flex justify-center items-center">
                  <a
                    href={ensureHttps(socialLink?.url)}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`Follow us on ${socialLink?.platform}`}
                  >
                    <Icon />
                  </a>
                </p>
              </div>
            );
          })}
        </div>

        <div className="mt-8 lg:mt-10">
          <SearchInput handleSearch={() => setHamburgerMenuOpen(false)} />
        </div>

        <div className="flex flex-wrap items-center justify-center gap-3 mt-6">
          {customPages?.map((item, index) => {
            return (
              <Link href={item?.link} key={index} onClick={() => setHamburgerMenuOpen(false)}>
                <span
                  className={
                    "bg-[#eee] dark:bg-[#555] px-2 py-1 text-sm font-medium rounded-md " +
                    (index === 0 ? " text-yellow-500 " : " dark:text-white ")
                  }
                >
                  {item?.name}
                </span>
              </Link>
            );
          })}
        </div>

        <div className="my-10">
          <div className="flex items-center gap-10 border-b border-[#676767]">
            {tabs?.map((tab, index) => {
              return (
                <div
                  key={index}
                  className={
                    "py-2 " + (index === 0 ? "border-b-2 border-b-[#000] dark:border-white " : "")
                  }
                >
                  <span
                    className={
                      "font-medium " +
                      (index === 0 ? "text-black dark:text-white " : " text-[#aaa] ")
                    }
                  >
                    {tab?.name}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="grid grid-cols-1 mt-8 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-x-4 gap-y-5">
            {categories &&
              categories?.length > 0 &&
              categories?.map((category, index) => {
                return (
                  <div key={index}>
                    <div className="mb-2">
                      <Link
                        href={`/${category?.slug}`}
                        onClick={() => setHamburgerMenuOpen(false)}
                        aria-label={`Go to ${category?.name} page`}
                      >
                        <span className="uppercase font-[600] text-sm dark:text-white ">
                          {category?.name}
                        </span>
                      </Link>
                    </div>
                    <ul>
                      {category?.subCategories?.map((subCategory, index) => {
                        return (
                          <li key={index} className="pb-[6px] last:pb-0">
                            <Link
                              href={`/${category?.slug}/${subCategory?.slug}`}
                              onClick={() => setHamburgerMenuOpen(false)}
                              aria-label={`Go to ${subCategory?.name} page`}
                            >
                              <span className="text-sm text-[#2F2F2F] dark:text-secondaryText">
                                {subCategory?.name}
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="block md:hidden">
          <button
            className="text-lg outline-none"
            onClick={toggleDarkMode}
            aria-label="Toggle Theme"
          >
            {darkMode ? (
              <div className="flex items-center ">
                <span className="p-2 text-white bg-black border border-gray-600 rounded hover:bg-white hover:text-black">
                  <FaSun />
                </span>
                <span className="px-4 font-semibold text-white font-anek">Light</span>
              </div>
            ) : (
              <div className="flex items-center border-gray-700">
                <span className="p-2 border border-black border-dashed rounded hover:bg-black hover:text-white">
                  <FaMoon />
                </span>
                <span className="px-4 font-semibold font-anek">Dark</span>
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
