"use client";

import envConfig from "@/config/envConfig";
import { MAX_COPY_TEXT_LENGTH } from "@/data/constants";
import { usePreventCopy } from "@/hooks/usePreventCopy";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

export const CopyPrevention = () => {
  const [currentUrl, setCurrentUrl] = useState("");
  const pathName = usePathname();

  usePreventCopy(MAX_COPY_TEXT_LENGTH, `Read more at: ${currentUrl || envConfig?.clientUrl}`);

  useEffect(() => {
    setCurrentUrl(`${envConfig?.clientUrl}${pathName}`);
  }, [pathName]);

  return null;
};
