export const getNewsDetailsUrl = (
  categoryId: { slug: string } | undefined,
  subCategoryId: { slug: string } | undefined,
  slug: string | undefined,
): string => {
  if (!slug || !categoryId || !categoryId.slug) {
    console.warn("Missing required slug or category slug");
    return "/404";
  }

  const categorySlug = categoryId?.slug || "";
  const subCategorySlug = subCategoryId?.slug || "";

  let url = `/${categorySlug}/${subCategorySlug}/${slug}`;

  return url;
};
