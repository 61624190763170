"use client";

import { redirect, usePathname, useRouter } from "next/navigation";
import React, { useEffect, useState } from "react";
import { IconType } from "react-icons";
import { GoHome } from "react-icons/go";
import { IoPlayOutline, IoSearch } from "react-icons/io5";
import { RxImage } from "react-icons/rx";
import { TbUser } from "react-icons/tb";
import { useAuthContext } from "../providers/AuthContextProvider";

interface MenuType {
  idx: number;
  name: string;
  logo: IconType;
  status: boolean;
  path: string;
}

const options: MenuType[] = [
  {
    idx: 1,
    name: "Home",
    logo: GoHome,
    status: true,
    path: "/",
  },
  {
    idx: 2,
    name: "Shorts",
    logo: IoPlayOutline,
    status: false,
    path: "/videos/shorts",
  },
  {
    idx: 3,
    name: "Stories",
    logo: RxImage,
    status: false,
    path: "/visual-stories",
  },
  {
    idx: 4,
    name: "Search",
    logo: IoSearch,
    status: false,
    path: `/search`,
  },
  {
    idx: 5,
    name: "Me",
    logo: TbUser,
    status: false,
    path: "/user/profile",
  },
];

const BottomNavigator = () => {
  const [option, setOption] = useState<MenuType[]>(options);

  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const pathname = usePathname();
  const router = useRouter();

  const { user } = useAuthContext();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY) {
        // Scrolling down
        setIsVisible(false);
      } else {
        // Scrolling up
        setIsVisible(true);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  useEffect(() => {
    // Determine which option should be active based on the URL
    const updatedOptions = options.map(data => {
      if (
        (pathname === "/" && data.name === "Home") ||
        (pathname.startsWith("/videos/shorts") && data.name === "Shorts") ||
        (pathname.startsWith("/visual-stories") && data.name === "Stories") ||
        (pathname.startsWith("/user") && data.name === "Me") ||
        (pathname.startsWith("/search") && data.name === "Search")
      ) {
        return { ...data, status: true };
      } else if (
        !pathname.startsWith("/videos/shorts") &&
        !pathname.startsWith("/visual-stories") &&
        !pathname.startsWith("/user") &&
        !pathname.startsWith("/search") &&
        data.name === "Home"
      ) {
        return { ...data, status: true };
      } else {
        return { ...data, status: false };
      }
    });

    setOption(updatedOptions);
  }, [pathname]);

  const handleClick = (id: number) => {
    const clickedOption = options.find(data => data.idx === id);
    if (clickedOption) {
      if (clickedOption?.idx === 5 && !user) {
        router.push("/register");
      } else {
        router.push(clickedOption.path);
      }
    }
  };
  return (
    <div
      className={`fixed block md:hidden bottom-5  w-full h-12 px-10 transition-transform duration-500 ${
        isVisible ? "translate-y-0" : "translate-y-[100px]"
      }`}
    >
      <div className="w-full h-full bg-[#333333]/70 rounded-full grid grid-cols-6 gap-2 px-2">
        {option.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => handleClick(item?.idx)}
              className={` ${
                item?.status ? " col-span-2 " : ""
              } text-white flex justify-center items-center transition-all duration-1000 cursor-pointer`}
            >
              <div
                className={`flex justify-center items-center gap-1 transition-all duration-500 ${
                  item?.status ? " text-white bg-white/30 py-1 px-3 rounded-full " : ""
                }`}
              >
                <span className="">
                  <item.logo size={25} />
                </span>
                {item?.status ? (
                  <span className="text-sm font-semibold font-montserrat">{item?.name}</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BottomNavigator;
